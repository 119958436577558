// Copy pasted from: https://github.com/InseeFrLab/keycloakify/blob/main/src/lib/components/shared/Template.tsx
import { assert } from "keycloakify/lib/tools/assert";
import { clsx } from "keycloakify/lib/tools/clsx";
import type { TemplateProps } from "keycloakify/lib/KcProps";
import { usePrepareTemplate } from "keycloakify/lib/Template";
import type { KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import citybreakLogo from "./assets/citybreak-logo.svg";
import iticketLogo from "./assets/iticket-logo.svg";
import bookvisitLogo from "./assets/bookvisit-logo.svg";
import webxLogo from "./assets/webx-logo.svg";
import visitcircleLogo from "./assets/visit-circle.svg";
import visitLogo from "./assets/visit-logo.svg";

export default function Template(props: TemplateProps<KcContext, I18n>) {
    const {
        displayInfo = false,
        displayMessage = true,
        displayRequiredFields = false,
        displayWide = false,
        showAnotherWayIfPresent = true,
        headerNode,
        showUsernameNode = null,
        formNode,
        infoNode = null,
        kcContext,
        i18n,
        doFetchDefaultThemeResources,
        stylesCommon,
        styles,
        scripts,
        kcHtmlClass
    } = props;

    const { msg, changeLocale, labelBySupportedLanguageTag, currentLanguageTag } = i18n;

    const { realm, locale, auth, url, message, isAppInitiatedAction, pageId } = kcContext;
    const tmpArr = pageId.split(".");
    const pageClass = "page-" + tmpArr[0];
    const { isReady } = usePrepareTemplate({
        doFetchDefaultThemeResources,
        stylesCommon,
        styles,
        scripts,
        url,
        kcHtmlClass
    });

    if (!isReady) {
        return null;
    }

    return (
        <div className={clsx(props.kcLoginClass, "d-flex flex-column flex-lg-row p-0")}>

            <div id="kc-billboard" className="justify-content-center d-none d-lg-flex">

                <div className="kc-brand position-absolute">
                    <a className="d-block w-150px" href="//visitgroup.com" target={"blank"}>
                        <img src={visitLogo} alt="Visit Logo" />
                    </a>
                </div>
                <div id="billboard-wrapper" className="d-flex flex-column justify-content-center py-5 px-3 w-100">
                    <img className="d-none mx-auto w-100px pb-lg-3 mb-5" src={visitcircleLogo} alt="" />
                    <div className="instructions">
                        <p className="mb-4">
                            <span className="d-block pb-2 font-weight-bold">First time on this page?</span>
                            If you use Microsoft in your company. Click on the Microsoft-button and connect your professional Microsoft account with the Visit account. Same goes for Google.
                            <span className="d-block py-2">or</span>
                            Click on <span className="text-underlined">Register</span> in the bottom of the page and create a new user.
                        </p>
                        <p className="mb-4">
                            <span className="d-block pb-2 font-weight-bold">Been here before?</span>
                            Click on the Microsoft-button
                            or
                            write the e-mail address under “Sign in” you used when you created a new Visit account user.
                        </p>
                        <p>
                            <span className="d-block pb-2 font-weight-bold">Questions?</span>
                            Send an e-mail to <a href="mailto:help@visit.com">help@visit.com</a> or <a href="https://www.visitgroup.com/ssofaq" target="_blank" rel="noreferrer">read our FAQ</a>.
                        </p>
                    </div>
                    <div className="d-none">
                    <h1 className="d-none d-lg-block fw-bold text-center mb-3">Manage every system <br /> with <span className="text-underlined">one account</span></h1>
                    <div className="d-none d-lg-block fs-base text-center">We're making your everyday work easier by giving you <b>one</b> account to manage all our systems. All you need to do is to register a Visit Account.
                    </div>
                    <div className="row my-5">
                        <div className="col-6">
                            <img className="d-none d-lg-block mx-auto mb-4" src={citybreakLogo} alt="" />
                            <img className="d-none d-lg-block ml-auto mw-80 " src={webxLogo} alt="" />
                        </div>
                        <div className="col-6">
                            <img className="d-none d-lg-block mx-auto mb-4 pb-2" src={bookvisitLogo} alt="" />
                            <img className="d-none d-lg-block mw-80 " src={iticketLogo} alt="" />
                        </div>
                    </div>
                    </div>
                </div>

            </div>
            <div className={clsx(pageClass, props.kcFormCardClass, displayWide && props.kcFormCardAccountClass, "d-flex flex-column justify-content-center align-items-center flex-nowrap p-2 p-lg-5")}>
                <header className={clsx(props.kcFormHeaderClass)}>
                    {realm.internationalizationEnabled && (assert(locale !== undefined), true) && locale.supported.length > 1 && (
                        <div id="kc-locale">
                            <div id="kc-locale-wrapper" className={clsx(props.kcLocaleWrapperClass)}>
                                <div className="kc-dropdown" id="kc-locale-dropdown">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" id="kc-current-locale-link">
                                        {labelBySupportedLanguageTag[currentLanguageTag]}
                                    </a>
                                    <ul>
                                        {locale.supported.map(({ languageTag }) => (
                                            <li key={languageTag} className="kc-dropdown-item">
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <a href="#" onClick={() => changeLocale(languageTag)}>
                                                    {labelBySupportedLanguageTag[languageTag]}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    {!(auth !== undefined && auth.showUsername && !auth.showResetCredentials) ? (
                        displayRequiredFields ? (
                            <div className={clsx(props.kcContentWrapperClass)}>
                                <div className={clsx(props.kcLabelWrapperClass, "subtitle")}>
                                    <span className="subtitle">
                                        <span className="required">*</span>
                                        {msg("requiredFields")}
                                    </span>
                                </div>
                                <div className="col-md-10">
                                    <h1 id="kc-page-title">{headerNode}</h1>
                                    <a href="https://help.visit.com/en/support/solutions/articles/12000093586-visit-group-sso" target="_blank" rel="noreferrer">What account do i use here?</a>
                                </div>
                            </div>
                        ) : (
                            <div className="flex-column">
                                <h1 id="kc-page-title" className="text-left">{headerNode}</h1>
                                <a href="https://help.visit.com/en/support/solutions/articles/12000093586-visit-group-sso" 
                                target="_blank" rel="noreferrer" className="mb-1 d-block">What account do i use here?</a>
                            </div>
                        )
                    ) : displayRequiredFields ? (
                        <div className={clsx(props.kcContentWrapperClass)}>
                            <div className={clsx(props.kcLabelWrapperClass, "subtitle")}>
                                <span className="subtitle">
                                    <span className="required">*</span> {msg("requiredFields")}
                                </span>
                            </div>
                            <div className="col-md-10">
                                {showUsernameNode}
                                <div className={clsx(props.kcFormGroupClass)}>
                                    <div id="kc-username">
                                        <label id="kc-attempted-username">{auth?.attemptedUsername}</label>
                                        <a id="reset-login" href={url.loginRestartFlowUrl}>
                                            <div className="kc-login-tooltip">
                                                <i className="fa-duotone fa-turn-down-left"></i>
                                                <span className="kc-tooltip-text">{msg("restartLoginTooltip")}</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {showUsernameNode}
                            <div className={clsx(props.kcFormGroupClass)}>
                                <div id="kc-username">
                                    <label id="kc-attempted-username">{auth?.attemptedUsername}</label>
                                    <a id="reset-login" href={url.loginRestartFlowUrl}>
                                        <div className="kc-login-tooltip">
                                            <i className="fa-duotone fa-turn-down-left"></i>
                                            <span className="kc-tooltip-text">{msg("restartLoginTooltip")}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </>
                    )}
                </header>
                <div id="kc-content">
                    <div id="kc-content-wrapper">
                        {/* App-initiated actions should not see warning messages about the need to complete the action during login. */}
                        {displayMessage && message !== undefined && (message.type !== "warning" || !isAppInitiatedAction) && (
                            <div className={clsx("alert", `alert-${message.type}`)}>
                                {message.type === "success" && <span className={clsx(props.kcFeedbackSuccessIcon)}></span>}
                                {message.type === "warning" && <span className={clsx(props.kcFeedbackWarningIcon)}></span>}
                                {message.type === "error" && <span className={clsx(props.kcFeedbackErrorIcon)}></span>}
                                {message.type === "info" && <span className={clsx(props.kcFeedbackInfoIcon)}></span>}
                                <span
                                    className="kc-feedback-text"
                                    dangerouslySetInnerHTML={{
                                        "__html": message.summary
                                    }}
                                />
                            </div>
                        )}
                        {formNode}
                        {auth !== undefined && auth.showTryAnotherWayLink && showAnotherWayIfPresent && (
                            <form
                                id="kc-select-try-another-way-form"
                                action={url.loginAction}
                                method="post"
                                className={clsx(displayWide && props.kcContentWrapperClass)}
                            >
                                <div className={clsx(displayWide && [props.kcFormSocialAccountContentClass, props.kcFormSocialAccountClass])}>
                                    <div className={clsx(props.kcFormGroupClass)}>
                                        <input type="hidden" name="tryAnotherWay" value="on" />
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a href="#" id="try-another-way" onClick={() => {
                                            document.forms["kc-select-try-another-way-form" as never].submit();
                                            return false;
                                        }}>
                                            {msg("doTryAnotherWay")}
                                        </a>
                                    </div>
                                </div>
                            </form>
                        )}
                        {displayInfo && (
                            <div id="kc-info" className={clsx(props.kcSignUpClass)}>
                                <div id="kc-info-wrapper" className={clsx(props.kcInfoAreaWrapperClass)}>
                                    {infoNode}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div id="kc-header" className={clsx(props.kcHeaderClass)} style={{ "display": "none" }}>
                <div id="kc-header-wrapper" className={clsx(props.kcHeaderWrapperClass)}>
                    {msg("loginTitleHtml", realm.displayNameHtml)}
                </div>
            </div>
        </div>
    );
}
